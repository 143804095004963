<template>
  <MainLayout v-loading="exceldownloading" :element-loading-text="nowExcelDoadingPercent">
    <ContentHeaderTabs
      v-if="isStudioOwner || canExcelDownloadMembers"
      :tabs="headerTabs"
      :activeTab="$route.path"
      :exceldownloading="exceldownloading"
      :handleExcelDownloadClick="
        () => {
          showExcelDownloadDialog = true;
        }
      "
    />
    <ContentHeaderTabs
      v-if="!isStudioOwner && !canExcelDownloadMembers"
      :tabs="headerTabs"
      :activeTab="$route.path"
      :exceldownloading="exceldownloading"
    />
    <MembersListFilter
      :filterValues="filterValues"
      :filterOptions="filterOptionsPolicyApplied"
      :filteredTotal="total"
      @filter-change="values => setFilterValues(values)"
      @reset-click="setResetFilter()"
    />

    <members-list
      v-loading="loading"
      :members="members"
      :filterValues="filterValues"
      @filter-change="values => setFilterValues(values)"
      @sendMessageCheked="sendMessageCheked"
      @sendMessageAll="sendMessageAll"
      showLastAttendedOn
      :sortType="sortType"
      :sortRule="sortRule"
      :reset="reset"
      @reset-event="getResetData"
      :getMemberAll="getMemberAll"
      :dispatchGetMembersList="dispatchGetMembersList"
      :pagination="pagination"
    />

    <el-pagination
      class="members__pagination"
      @current-change="dispatchGetMembersList"
      :current-page.sync="page"
      :page-size="limit"
      :page-sizes="[10, 15, 20, 50]"
      @size-change="limit => handleFilterChange({ limit })"
      layout="prev, pager, next, sizes"
      :total="total"
    />

    <FloatingActionButton v-if="canCreateMembers" @click="$router.push('/users/create')" />

    <MemberListExcelDownloadDialog
      v-if="showExcelDownloadDialog"
      :show="showExcelDownloadDialog"
      :onClose="handleExcelDownloadDialogClose"
    />

    <el-dialog class="message-modal" :visible.sync="showMessageDialog">
      <CustomTabs
        class="message-modal__tabs"
        :active_tab="messageTypeTab"
        :tabs="[
          { value: 'sms', label: 'SMS 보내기', disabled: !canSendSmsMessage },
          { value: 'message', label: '앱푸시 보내기', disabled: !canSendAppPushMessage },
        ]"
        :handleChangeTab="handleMessageTab"
      />
      <MessageForm
        v-if="showMessageDialog"
        hide-member-list-button
        is-on-modal
        :members="messageTypeTab === 'sms' ? receiver.sms : receiver.message"
        :messageType="messageTypeTab"
        @messageSent="showMessageDialog = false"
        placeholder="40바이트 제한 및 특수문자 (), <>, [] 사용가능"
      />
    </el-dialog>

    <SmsPushCombineModal
      :show="showMessageSelectDialog"
      :membersAppLinked="
        messageTypeTab === 'sms'
          ? { booked: receiver.sms, waiting: [], all: receiver.sms }
          : { booked: receiver.message, waiting: [], all: receiver.message }
      "
      :memberIdsForMessage="messageTypeTab === 'sms' ? memberIdsForMessage.sms : memberIdsForMessage.message"
      :isMemberSelectedAll="isMemberSelectedAll"
      :sendType="messageTypeTab"
      @handleCheckboxChange="handleCheckboxChange"
      @changeSendType="value => (messageTypeTab = value)"
      @toggleSelectAll="toggleSelectAll"
      @close="onMessageSent"
    />
  </MainLayout>
</template>

<script>
import MembersList from '@components/Members/List';
import MembersListFilter from '@components/Members/ListFilter';
import MemberListExcelDownloadDialog from '@components/Modals/MemberListExcelDownload';
import MessageForm from '@/components/Messages/Form';
import SmsPushCombineModal from '@/components/Modals/SmsPushCombineModal';
import { CONTENT_LABEL } from '@/constants';

export default {
  components: {
    MembersList,
    MembersListFilter,
    MemberListExcelDownloadDialog,
    MessageForm,
    SmsPushCombineModal,
  },

  data() {
    return {
      headerTabs: [
        { path: '/users', label: '회원' },
        { path: '/users/counseling', label: '상담고객' },
        { path: '/users/ticket-history', label: '수강권 정보 변경이력' },
      ],
      exceldownloading: false,
      showExcelDownloadDialog: false,
      excelPage: {
        page: 1,
        limit: 150,
      },
      nowExcelDoadingPage: 0,
      excelTotalPageCount: 1,
      sortType: {
        sort_name: null,
        sort_date: null,
        sort_attendance: null,
      },
      sortRule: { prop: 'sort_name', order: 'ascending' },
      reset: false,

      /** sms/message 메세지 모달 관련 데이터 */
      showMessageDialog: false,
      showMessageSelectDialog: false,
      messageTypeTab: 'sms',
      receiver: { sms: [], message: [] },
      memberIdsForMessage: { sms: [], message: [] },
    };
  },

  computed: {
    isStudioOwner() {
      return this.$store.getters['auth/isStudioOwner'];
    },
    loading() {
      return this.$store.getters['members/loading'];
    },
    members() {
      return this.$store.getters['members/members'];
    },
    total() {
      return this.$store.getters['members/total'];
    },
    pagination() {
      return this.$store.getters['members/pagination'];
    },
    filterOptions() {
      return this.$store.getters['members/filterOptions'];
    },
    filterValues() {
      return this.$store.getters['members/filterValues'];
    },

    page: {
      get() {
        return this.pagination.page;
      },
      set(page) {
        this.setPagination({ page });
      },
    },
    limit: {
      get() {
        return this.pagination.limit;
      },
      set(limit) {
        this.setPagination({ limit });
      },
    },

    filterOptionsPolicyApplied() {
      const useUserGrades = !!_.get(this.studioPolicies, 'is_use_user_grade');
      return useUserGrades ? this.filterOptions : _.omit(this.filterOptions, 'user_grade');
    },

    nowExcelDoadingPercent() {
      if (this.nowExcelDoadingPage) {
        return `${this.nowExcelDoadingPage} / ${this.excelTotalPageCount}`;
      }
      return null;
    },

    isMemberSelectedAll() {
      const isSelectedAll = () => {
        return this.receiver[this.messageTypeTab].every(({ id }) => {
          return this.memberIdsForMessage[this.messageTypeTab].includes(id);
        });
      };
      const booked = isSelectedAll();
      return { booked: booked, waiting: true };
    },

    selectForSendMessageCount() {
      return this.$store.getters['members/selectForSendMessageCount'];
    },
  },

  watch: {
    filterValues() {
      this.setPagination({ page: 1 });
      this.dispatchGetMembersList();
    },

    members() {
      if (!this.members.length && this.page > 1) {
        this.setPagination({ page: this.page - 1 });
        this.dispatchGetMembersList();
      }
    },

    '$route.query': {
      handler: 'setMembersFilterValues',
      immediate: true,
    },
    $route: {
      handler() {
        const useUserGrades = !!_.get(this.studioPolicies, 'is_use_user_grade');
        if (!useUserGrades && this.filterValues.sort_user_grade_id) {
          this.setFilterValues({
            ...this.filterValues,
            sort_user_grade_id: null,
          });
        }
      },
      immediate: true,
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.canViewMembers) {
        return next('/users/counseling');
      }

      next();
    });
  },

  created() {
    this.dispatchGetMembersList();
    if (!this.canSendSmsMessage) {
      this.messageTypeTab = 'message';
    }
  },

  destroyed() {
    if (this.selectForSendMessageCount) {
      this.$store.commit('members/SET_SEND_MESSAGE_COUNT', 0);
    }
  },

  methods: {
    getResetData(data) {
      this.reset = data;
    },

    handleCheckboxChange(value) {
      if (this.memberIdsForMessage[this.messageTypeTab].includes(value)) {
        this.memberIdsForMessage[this.messageTypeTab] = this.memberIdsForMessage[this.messageTypeTab].filter(v => v !== value);
      } else {
        this.memberIdsForMessage[this.messageTypeTab].push(value);
      }
    },

    toggleSelectAll(status) {
      if (this.isMemberSelectedAll[status]) {
        this.memberIdsForMessage[this.messageTypeTab] = this.memberIdsForMessage[[this.messageTypeTab]].filter(id => {
          return !this.receiver[this.messageTypeTab].find(member => member.id === id);
        });
      } else {
        this.memberIdsForMessage[this.messageTypeTab] = _.uniq([
          ...this.memberIdsForMessage[this.messageTypeTab],
          ...this.receiver[this.messageTypeTab].map(({ id }) => id),
        ]);
      }
    },

    async sendMessageAll() {
      const {
        type,
        user_grade,
        course_type,
        remaining_coupon,
        remaining_day,
        last_attendance_day,
        vaccination_yn,
      } = this.filterValues;
      this.exceldownloading = true;

      /** sms 대상 */
      const data = await this.getMemberAll('profile', false);
      if (!data.length) {
        this.exceldownloading = false;
        return this.$utils.notify.parseError(this, { message: '메시지를 전송 가능한 회원이 없습니다.' });
      }

      /** 전체 회원 대상시 선택 불가능한 메세지 모달 팝업 */
      if (type || user_grade || course_type || remaining_coupon || remaining_day || last_attendance_day || vaccination_yn) {
        this.showMessageSelectDialog = true;
      } else {
        this.showMessageDialog = true;
      }

      /** 푸시 대상 */
      const account = data.filter(({ has_user_account }) => has_user_account);
      this.receiver = { sms: data, message: account };

      this.memberIdsForMessage = {
        sms: _.uniq(this.receiver.sms.map(({ id }) => id)),
        message: _.uniq(this.receiver.message.map(({ id }) => id)),
      };

      this.exceldownloading = false;
    },

    sendMessageCheked(data) {
      if (!data.length) return this.$utils.notify.parseError(this, { message: '체크한 회원이 없습니다.' });

      data = data.filter(({ mobile }) => mobile);
      if (!data.length) return this.$utils.notify.parseError(this, { message: '메시지를 전송 가능한 회원이 없습니다.' });

      this.exceldownloading = true;
      this.showMessageSelectDialog = true;

      const account = data.filter(({ has_user_account }) => has_user_account);
      this.receiver = { sms: data, message: account };

      this.memberIdsForMessage = {
        sms: _.uniq(this.receiver.sms.map(({ id }) => id)),
        message: _.uniq(this.receiver.message.map(({ id }) => id)),
      };

      this.exceldownloading = false;
    },

    setFilterValues(values) {
      this.$store.commit('members/SET_FILTER_VALUES', values);
    },

    setResetFilter() {
      this.reset = true;
      this.$store.commit('members/SET_FILTER_VALUES');
    },

    setPagination(values) {
      this.$store.commit('members/SET_PAGINATION', values);
    },

    dispatchGetMembersList(page) {
      if (page) this.pagination.page = page;

      this.$store.dispatch('members/getMembers', {
        ...this.pagination,
        ...this.filterValues,
      });
    },

    setMembersFilterValues() {
      const { remaining_day, remaining_coupon } = this.$route.query;

      if (remaining_day) {
        this.setFilterValues({
          ...this.filterValues,
          remaining_day: Number(remaining_day),
        });
      }

      if (remaining_coupon) {
        this.setFilterValues({
          ...this.filterValues,
          remaining_coupon: Number(remaining_coupon),
        });
      }
    },

    handleFilterChange({ limit }) {
      this.setPagination({ limit });
      this.dispatchGetMembersList();
    },

    handleExcelDownloadDialogClose(result, values) {
      this.showExcelDownloadDialog = false;

      if (result) {
        const properties = {
          name: ['이름'],
          grade: ['등급'],
          mobile: ['전화번호'],
          email: ['이메일'],
          appLink: ['앱연결'],
          registeredOn: ['등록일'],
          lastAttendedOn: ['최근출석일'],
          gender: ['성별'],
          birthday: ['생년월일'],
          address: ['주소', '상세주소'],
          memo: ['메모'],
          ticketTitle: ['수강권명'],
          ticketType: ['수강권종류'],
          ticketPeriod: ['수강권시작일', '수강권종료일'],
          isShared: ['패밀리수강권'],
          counts: ['전체횟수', '잔여횟수', '예약가능횟수', '취소가능횟수'],
          issuedOn: ['수강권발급일'],
          updatedOn: ['수강권최종수정일'],
          payments: ['결제구분', '결제금액', '결제일시', '결제방법', '할부개월수'],
          status: ['수강권상태'],
          staffs: ['담당강사'],
        };

        const excelDownloadProperties = values.reduce((converted, value) => converted.concat(properties[value]), []);
        if (values.includes('includeExpired')) {
          this.downloadExcel(excelDownloadProperties, values.includes('includeExpired'));
        } else {
          this.downloadExcel(excelDownloadProperties);
        }
      }
    },

    async getMemberAll(withParams, includeExpired) {
      let params = {
        ...this.$utils.mapParamsForMemberList(this.filterValues),
        with: withParams,
        all_ticket: includeExpired,
      };

      if (withParams === 'profile') {
        params = { ...params, is_mobile: true };
      }

      // excel 다운로드 관련 params, loading progress 초기화
      this.nowExcelDoadingPage = 0;
      this.excelPage.page = 1;

      const firstRes = await this.$api.member.getAll({ ...params, ...this.excelPage });

      const resArray = firstRes.data.data;
      this.excelTotalPageCount = firstRes.data.meta.last_page;

      for (let i = 1; i < firstRes.data.meta.last_page; i++) {
        this.excelPage.page++;
        const res = await this.$api.member.getAll({ ...params, ...this.excelPage });
        resArray.push(res.data.data);
        this.nowExcelDoadingPage = this.excelPage.page;
      }

      return resArray.flat();
    },

    handleMessageTab(tab) {
      this.messageTypeTab = tab;
    },

    onMessageSent() {
      this.showMessageSelectDialog = false;
    },

    /** 아래부터 엑셀 다운로드 관련 함수 */

    async downloadExcel(propsToDownload, includeExpired) {
      this.exceldownloading = true;
      try {
        const data = await this.getMemberAll(
          'tickets.payments;bookings;profile;account;userGrade;address;avatars;tickets.ticket;tickets.staffs;memos',
          includeExpired,
        );

        const json = this.formatJSON(data, propsToDownload);
        const title = `회원목록_${this.moment().format('YYYYMMDD_HHmm')}.xlsx`;

        /** 다운로드 */
        await this.$utils.downloadExcel(json, title);
        this.nowExcelDoadingPage = this.excelTotalPageCount + 1;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.exceldownloading = false;
        this.nowExcelDoadingPage = 0;
        this.excelPage.page = 1;
      }
    },

    formatJSON(members, propsToDownload) {
      let membersJSON = [];

      members.forEach(member => {
        const memberInfo = this.mapMemberInfo(member);
        const reducedMemberInfo = this.mapPropsToDownload(memberInfo, propsToDownload);

        const ticketProps = [
          '수강권명',
          '수강권종류',
          '수강권시작일',
          '수강권종료일',
          '패밀리수강권',
          '전체횟수',
          '잔여횟수',
          '예약가능횟수',
          '취소가능횟수',
          '수강권발급일',
          '수강권최종수정일',
          '수강권상태',
          '담당강사',
        ];

        /** 보유 수강권 없거나 수강권셀 관한 항목을 선택하지 않았을 경우 */
        if (member.userTickets.length <= 0 || !ticketProps.some(prop => propsToDownload.includes(prop))) {
          membersJSON.push(reducedMemberInfo);

          /** 보유 수강권이 있으면 수강권 갯수만큼 행 추가 */
        } else {
          member.userTickets.forEach(userTicket => {
            const ticketInfo = this.mapTicketInfo(userTicket);
            const reducedTicketInfo = this.mapPropsToDownload(ticketInfo, propsToDownload);

            const paymentProps = ['결제구분', '결제금액', '결제일시', '결제방법', '할부개월수'];

            /** 결제정보가 없거나 결제정보를 선택하지 않았을 경우 */
            if (userTicket.payments.length < 0 || !paymentProps.some(prop => propsToDownload.includes(prop))) {
              membersJSON.push({ ...reducedMemberInfo, ...reducedTicketInfo });

              /** 결제정보 갯수만큼 행 추가 */
            } else {
              userTicket.payments.forEach((payment, index) => {
                const paymentInfo = this.mapPaymentInfo(payment);
                const nextPaymentStatus = _.get(userTicket, `payments[${index + 1}].status`);
                const hasNextPayment = ['upgrade', 'refund'].includes(nextPaymentStatus);

                if (!hasNextPayment) {
                  membersJSON.push({
                    ...reducedMemberInfo,
                    ...reducedTicketInfo,
                    ...paymentInfo,
                  });

                  /** 후에 업그레이드 된 수강권일 경우 */
                } else {
                  const originalTicketInfo = this.mapOriginalTicketInfo(payment);
                  const reducedOriginalTicketInfo = this.mapPropsToDownload(originalTicketInfo, propsToDownload);
                  membersJSON.push({
                    ...reducedMemberInfo,
                    ...reducedOriginalTicketInfo,
                    ...paymentInfo,
                  });
                }
              });
            }
          });
        }
      });

      return membersJSON;
    },

    mapMemberInfo({
      name,
      mobile,
      email,
      grade,
      has_user_account,
      registered_at,
      lastAttendance,
      gender,
      birthday,
      address,
      detail_address,
      memos,
    }) {
      const mobileConverted = this.canViewMembersMobile ? this.$filters.mobile(mobile) : this.$filters.mobileMask(mobile);

      const GENDER = {
        M: '남성',
        F: '여성',
      };
      return {
        이름: name,
        등급: grade || '',
        전화번호: mobile ? mobileConverted : null,
        이메일: email || '',
        앱연결: has_user_account ? '연결' : '미연결',
        등록일: this.moment(registered_at).format('YYYY-MM-DD'),
        최근출석일: lastAttendance ? this.moment(lastAttendance).format('YYYY-MM-DD') : null,
        성별: GENDER[gender] || '',
        생년월일: this.$filters.dateKorean(birthday),
        주소: address || '',
        상세주소: detail_address || '',
        메모: memos.map(({ memo }) => memo).join('\n'),
      };
    },

    mapTicketInfo(userTicket) {
      const {
        ticket,
        availability_start_at,
        expire_at,
        is_shared,
        max_coupon,
        remaining_coupon,
        usable_coupon,
        remaining_cancel,
        staffs,
        created_at,
        staff_updated_at,
      } = userTicket;

      const noCoupon = ticket.type === 'P' || ticket.type === 'RP' || ticket.type === 'S';

      return {
        수강권명: ticket.title,
        수강권종류: ticket.available_class_type === 'I' ? '상품' : ticket.available_class_type === 'G' ? '그룹' : '프라이빗',
        수강권시작일:
          ticket.type === 'S' || ticket.type === 'RT' ? null : this.moment(availability_start_at).format('YYYY-MM-DD'),
        수강권종료일: ticket.type === 'S' || ticket.type === 'RT' ? null : this.moment(expire_at).format('YYYY-MM-DD'),
        패밀리수강권: is_shared ? 'Y' : 'N',
        전체횟수: noCoupon ? null : max_coupon,
        잔여횟수: noCoupon ? null : remaining_coupon,
        예약가능횟수: noCoupon ? null : usable_coupon,
        취소가능횟수: noCoupon || ticket.available_class_type === 'I' ? null : remaining_cancel,
        수강권발급일: this.moment(created_at).format('YYYY-MM-DD'),
        수강권최종수정일: staff_updated_at ? this.moment(staff_updated_at).format('YYYY-MM-DD') : null,
        수강권상태: this.$utils.getTicketStatus(userTicket),
        담당강사: staffs.map(({ name }) => name).join(', '),
      };
    },

    mapOriginalTicketInfo(payment) {
      const title = _.get(payment, 'parentGoods.title', '');
      const ticketType = _.get(payment, 'parentGoods.type');
      const maxCoupon = ticketType !== 'P' ? _.get(payment, 'parentGoods.max_coupon', 0) : null;

      return {
        수강권명: title,
        수강권종류: '',
        수강권시작일: '',
        수강권종료일: '',
        패밀리수강권: '',
        전체횟수: maxCoupon,
        잔여횟수: '',
        예약가능횟수: '',
        취소가능횟수: '',
        수강권발급일: '',
        수강권최종수정일: '',
        수강권상태: '업그레이드',
        담당강사: '',
      };
    },

    // NOTE: [S2-3619] 엑셀 다운로드 시 결제 방법이 계좌이체면 "계좌이체"로 표시해야 함
    // getPaymentMethod 유틸을 수정하면 예상치 못한 사이드 이펙트가 발생할 가능성이 있어 현재 메서드에서 수정
    mapPaymentInfo(payment) {
      // 결제 방법 판단
      const paymentMethodLabel = this.getPaymentMethodLabel(payment);

      // 할부 기간 정보 설정
      const installmentPeriod =
        !paymentMethodLabel || !paymentMethodLabel.includes('카드')
          ? ''
          : payment.installment_period <= 1
          ? '일시불'
          : payment.installment_period;

      const checkTypes = ['new', 're-take', 'experience'].some(type => type === payment.type);
      const currentPaymentStatus = checkTypes ? payment.type : payment.status;
      const resultPaymentStatus = this.$utils.translate.paymentStatus(currentPaymentStatus);

      return {
        결제구분: resultPaymentStatus,
        결제금액: payment.amount,
        결제일시: this.moment(payment.settlement_at).format('YYYY-MM-DD'),
        결제방법: paymentMethodLabel,
        할부개월수: installmentPeriod,
      };
    },

    mapPropsToDownload(data, propsToDownload) {
      return Object.keys(data).reduce((reduced, key) => {
        if (propsToDownload.includes(key)) reduced[key] = data[key];
        return reduced;
      }, {});
    },

    handleClostExtendTicket(refresh) {
      this.showExtendTicket = false;

      if (refresh) {
        this.$store.dispatch('ticket/getTicket', this.ticket.id);
      }
    },

    getPaymentMethodLabel(payment) {
      if (!payment.amount && !payment.transfer_amount) {
        return null;
      }

      const paymentTypes = {
        card_amount: CONTENT_LABEL.card_amount,
        cash_amount: CONTENT_LABEL.cash_amount,
        wiretransfer_amount: CONTENT_LABEL.wiretransfer_amount,
        point_amount: CONTENT_LABEL.point_amount,
      };

      const paymentDetails = Object.entries(payment).reduce((acc, [key, value]) => {
        if (key in paymentTypes && value > 0) {
          if (acc) {
            return `${acc} + ${paymentTypes[key]}`;
          }
          return paymentTypes[key];
        }
        return acc;
      }, '');
      return paymentDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
.members__pagination {
  @include flex(row, center, center);
  margin-top: 30px;

  /deep/ .el-pagination__sizes {
    margin: 0;

    /deep/ .el-input__inner {
      @extend %input-default;
      border-color: #dcdfe6;
      height: 36px;
    }

    /deep/ .el-input__suffix {
      transform: scale(1);
      padding-right: 8px;
    }
  }
}

.message-modal {
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__body {
    padding: 10px 20px 30px 20px;
  }

  &__tabs {
    justify-content: flex-start;
    padding-bottom: 30px;
  }
}
</style>
