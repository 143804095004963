<template>
  <MainLayout :padded="false">
    <content-header :breadcrumb="breadcrumb" :contentMaxWidth="1280">
      <div class="staff-form__header">
        <div class="staff-form__header__profile-image">
          <el-upload
            :class="{ hasProfileImage: !!profileImage }"
            ref="profile-image"
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            :file-list="profileImages"
            :auto-upload="false"
            :on-preview="handleProfileImagePreview"
            :on-change="handleProfileImageChange"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <circle-button
            v-if="!!profileImage || !!profileImages.length"
            class="staff-form__header__profile-image__remove"
            @click="handleClickRemoveProfileImage"
          >
            <i class="el-icon-close"></i>
          </circle-button>
          <el-dialog :visible.sync="showProfileImagePreviewDialog">
            <img width="100%" :src="profileImagePreviewUrl" alt="profile preview" />
          </el-dialog>
        </div>
        <input ref="name" class="staff-form__header__name" type="text" v-model="name" placeholder="이름을 입력해주세요" />
        <el-select class="staff-form__header__role" v-model="roleId" ref="roleId">
          <el-option v-for="role in roles" :key="role.id" :value="role.id" :label="role.display_name" />
        </el-select>
        <span class="staff-form__header__registered-at">
          등록일:
          <el-date-picker
            v-model="registeredAt"
            type="date"
            placeholder="등록일을 입력해주세요"
            format="yyyy년 M월 d일"
            value-format="yyyy-MM-dd"
            :clearable="false"
          ></el-date-picker>
        </span>

        <div class="staff-form__header_gender-birthday">
          <label>성별:</label>
          <el-select v-model="gender" class="staff-form__header__gender">
            <el-option v-for="item in genderOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <label>생년월일:</label>
          <DateInput class="staff-form__header__birthday" v-model="birthday" placeholder="생년월일 (YYYY-MM-DD)" />
        </div>
      </div>
    </content-header>

    <section class="staff-form__body">
      <form-block :index="1" label="대표컬러 설정">
        <div class="staff-form-element__color">
          <div class="staff-form-element__color__chips">
            <ColorChip
              v-for="(color, index) in colorChips"
              :key="index"
              :color="color"
              :checked="color === selectedColor"
              @click="handleColorSelect"
            />
          </div>
          <plain-button class="staff-form-element__color__add-new" @click="showColorsAll = !showColorsAll"
            >컬러 추가</plain-button
          >
          <p class="staff-form-element__color__helper">{{ messageOnColorSelected }}</p>
        </div>
      </form-block>

      <form-block :index="2" label="연락처 설정">
        <transition-group name="el-zoom-in-top">
          <ContactInput
            ref="contact"
            v-for="(contact, index) in contacts"
            :key="`${contact.id}${index}`"
            :contact="contact"
            :index="index"
            :handleClickAdd="handleClickAddContact"
            :handleClickRemove="handleClickRemoveContact"
            :disabled="index === 0 ? true : false"
            :showMobileTooltip="true"
          />
        </transition-group>
      </form-block>

      <form-block :index="3" label="모든 강사 알림 받기 설정">
        <el-checkbox slot="checkbox" v-model="getAllStaffsNotification" :disabled="!isStudioOwner">알림 설정</el-checkbox>
        <p class="staff-form-element__get-all-notification">
          모든 강사의 수업 변경 내역 알림을 받습니다.
        </p>
      </form-block>

      <form-block :index="4" label="강사 정보 설정">
        <div class="staff-form-element__introduction">
          <label>자기 소개</label>
          <TextInput v-model="introduction" placeholder="자기 소개를 입력하세요" material />
        </div>
        <div class="staff-form-element__careers">
          <label>주요 이력</label>
          <ul>
            <transition-group name="el-zoom-in-top">
              <li v-for="(career, index) in careers" :key="`${career.id}${index}`">
                <TextInput v-model="career.career" material />
                <el-button
                  :icon="index === 0 ? 'el-icon-plus' : 'el-icon-minus'"
                  size="mini"
                  circle
                  @click="index === 0 ? handleClickAddCareer() : handleClickRemoveCareer(index)"
                />
              </li>
            </transition-group>
          </ul>
        </div>
      </form-block>
      <form-block :index="5" label="프라이빗 수업 예약 시간 단위 설정">
        <el-tooltip style="margin-left:10px;" slot="label-helper" effect="light" placement="right-start">
          <!-- <DeadlineSettingsHelper slot="content" /> -->
          <PrivateStartIntervalTooltip slot="content" />
          <i class="el-icon-question" :style="{ color: '#64AEFF', fontSize: '18px' }" />
        </el-tooltip>
        <el-checkbox slot="checkbox" v-model="privateStartIntervalOn">설정함</el-checkbox>
        <p class="staff-form-element__get-all-notification">
          회원 앱에서 프라이빗 수업을 예약할 때 설정한 시간 단위로 나타납니다.
        </p>
        <div class="block">
          <div class="line">
            오전 <span class="time">00 : 00 ~ 12 : 00</span
            ><el-radio v-model="privateStartIntervalAm" :label="60" :disabled="!privateStartIntervalOn"> 정시 </el-radio
            ><el-radio v-model="privateStartIntervalAm" :label="30" :disabled="!privateStartIntervalOn">
              30분
            </el-radio>
          </div>
          <div class="line">
            오후 <span class="time">13 : 00 ~ 24 : 00</span>
            <el-radio v-model="privateStartIntervalPm" :label="60" :disabled="!privateStartIntervalOn"> 정시 </el-radio
            ><el-radio v-model="privateStartIntervalPm" :label="30" :disabled="!privateStartIntervalOn">
              30분
            </el-radio>
          </div>
        </div>
      </form-block>
      <form-block :index="6" noBtmBorder label="근무 시간 설정">
        <p class="txt-info-weekday">요일 별 설정한 근무 시간은 아래 달력에서 확인 할 수 있습니다.</p>
        <WorkingHourInput
          v-for="(weekday, index) in workingHoursByWeekday"
          :key="index"
          :weekday="weekday"
          :index="index"
          openHourLabel="근무시간"
          :addClosedHour="addRestHour"
          :removeClosedHour="removeRestHour"
          :applyToAllOpenHours="applyToAllOpenHours"
          :hasPeriod="true"
        />
      </form-block>
      <form-block :index="0" unNumbering label="">
        <p class="wrap-calendar-info-txt ">
          특정한 날의 근무시간, 휴식시간 또는 휴일을 설정하려면 달력의 날짜를 클릭해주세요.
        </p>
        <div class="staff-form-element__work-calendar">
          <div>
            <DotCalendar
              ref="calendar"
              @pick="handlePickDate"
              :existingBookingDates="existingBookingDates"
              :selectedDates="selectedDates"
              :setTimeDates="workingHoursByWeekday"
            />
            <div class="txt-exist-specific">
              <span class="reserved-circle"></span>
              <span>기존에 설정된 특정 근무/휴식시간, 휴무일</span>
              <ul v-for="(item, index) in existBookingsDateSets" :key="index">
                <li v-for="(el, index) in item" :key="index">{{ el | dateWithWeekday }}</li>
              </ul>
            </div>
          </div>
          <div>
            <div class="txt-selected-dates">
              {{ schedules.length | comma }} 개의 일정이 선택됨
              <PlainButton @click="schedules = []" :disabled="!schedules.length" round>선택 초기화</PlainButton>
            </div>
            <SetDateTimeRow
              v-for="(schedule, index) in schedules"
              :key="`${schedule.date}${index}`"
              v-model="schedules[index]"
              @removeExistData="removeExistData"
              @updateTimes="updateSameWeekdayTimes"
              @addRestRow="addRestRow"
              @removeRestRow="removeRestRow"
            />
          </div>
        </div>
      </form-block>
    </section>
    <bottom-action-bar :message="lastUpdatedAt">
      <el-button v-loading="isSaving" :disabled="isSaving" @click="saveStaff">{{ title }} 완료</el-button>
    </bottom-action-bar>
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex';
import PrivateStartIntervalTooltip from '@/components/StaffForm/PrivateStartIntervalTooltip';

export default {
  components: {
    PrivateStartIntervalTooltip,
  },
  data() {
    const { moment } = this;
    return {
      /** Options */
      genderOptions: [
        { label: '선택안함', value: null },
        { label: '남성', value: 'M' },
        { label: '여성', value: 'F' },
      ],

      /** 데이터 - 공통 */
      profileImage: null,
      name: '',
      roleId: null,
      registeredAt: this.moment().format('YYYY-MM-DD'),
      selectedColor: 'f95454',
      contacts: [
        { type_id: 2, contact: '', is_representative: true },
        { type_id: 1, contact: '', is_representative: false },
      ],
      getAllStaffsNotification: false,
      introduction: '',
      careers: [{ career: '' }],
      workingHoursByWeekday: {},

      /** 데이터 - 강사 수정시 */
      staff: null,
      profileImages: [],

      showColorsAll: false,
      staffColors: {},

      /** Profile Image Dialog */
      profileImagePreviewUrl: '',
      showProfileImagePreviewDialog: false,

      isSaving: false,
      /** 수업데이터 - 생성시 */
      daterange: [
        moment().format('YYYY-MM-DD'),
        moment()
          .add(3, 'month')
          .format('YYYY-MM-DD'),
      ],
      schedules: [],
      existingBookingDates: [],
      eventWorktimeDatas: [],

      privateStartIntervalOn: false,
      privateStartIntervalAm: 60,
      privateStartIntervalPm: 60,
      birthday: null,
      gender: null,
    };
  },

  computed: {
    referenceArray() {
      const referenceArray = [];
      _.range(7).map(item => {
        referenceArray[item] = [];
      });

      this.schedules.forEach(({ date }) => {
        let rep_no = this.moment(date).weekday();
        referenceArray[rep_no].push({ date });
        referenceArray[rep_no] = _.sortBy(referenceArray[rep_no], 'date');
      });

      return referenceArray;
    },
    firstWeekdays() {
      return this.referenceArray.map(el => {
        return el[0];
      });
    },
    isBlankCareer() {
      let result = false;
      if (this.careers.length) {
        this.careers.forEach(item => {
          item.id && item.career === '' ? (result = true) : (result = false);
        });
      }
      return result;
    },
    colorPresets() {
      return this.$store.getters['colorPresets/colorPlainPalette'];
    },
    roles() {
      return this.$store.getters['studio/roles'];
    },

    breadcrumb() {
      return {
        parent: { path: '/staffs', label: '강사' },
        current_route_label: this.title,
      };
    },

    title() {
      return !_.get(this.staff, 'id') ? '강사 등록' : '강사 수정';
    },

    lastUpdatedAt() {
      const date = _.get(this.staff, 'updated_at') || _.get(this.staff, 'created_at');
      if (!date) return null;

      const suffix = this.staff.updated_at ? '에 마지막으로 수정됨' : '에 생성됨';

      return `${this.$filters.datetime(date)} ${suffix}`;
    },

    colorChips() {
      let colorChips = [];
      if (!this.showColorsAll) {
        colorChips = [...this.colorPresets[1]];
        if (!colorChips.includes(this.selectedColor)) colorChips.push(this.selectedColor);
      } else {
        colorChips = this.colorPresets.reduce((combined, colorGroup) => combined.concat(colorGroup), []);
      }
      return colorChips;
    },

    messageOnColorSelected() {
      const isUsed = Object.keys(this.staffColors).includes(this.selectedColor);
      if (!isUsed) return '';

      return `${this.staffColors[this.selectedColor]}명의 다른 강사가 사용중인 색상입니다.`;
    },
    selectedDates() {
      return this.schedules.reduce((dates, { date }) => {
        if (!dates.includes(date)) dates.push(date);
        return dates;
      }, []);
    },
    existBookingsDateSets() {
      const result = [];
      let first_array = Array.from(new Set(this.existingBookingDates));
      first_array = _.sortBy(first_array);
      let count_no = 0;
      first_array.forEach(el => {
        if (!result.length) {
          return result.push([el]);
        }
        count_no++;
        if (first_array[count_no - 1].slice(0, 7) === el.slice(0, 7)) {
          const check_index = item => item[0].slice(0, 7) === el.slice(0, 7);
          const index_no = result.findIndex(check_index);
          result[index_no].push(el);
        } else {
          result.push([el]);
        }
      });
      return result;
    },
    /** 셀렉박스에서 스튜디오 오너 옵션 선택 했을 때 */
    isOwner() {
      return !!this.roles.filter(role => role.id === this.roleId && role.name === 'studio_owner').length;
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      const authorized = !to.query.id ? vm.canCreateStaff : vm.canUpdateStaff || to.query.id == vm.currentUser.id;

      if (!authorized) {
        vm.$utils.notify.error(vm, '오류', '권한이 없습니다.');
        return next('/staffs');
      }

      next();
    });
  },

  async created() {
    await this.$store.dispatch('studio/getRoles');

    const staffId = this.$route.query.id;
    if (!staffId) {
      this.setWorkingHours();
    } else {
      try {
        this.getStaffWorkTimes(staffId);
        const res = await this.$api.staff.get(staffId);
        this.staff = res.data.staff;

        const hasContactInfos = !!_.get(this.staff, 'contact_infos').length;

        this.name = _.get(this.staff, 'profile.name');
        this.roleId = _.get(this.staff, 'roles[0].id');
        this.registeredAt = _.get(this.staff, 'profile.registered_at');
        this.selectedColor = _.get(this.staff, 'profile.representative_color');
        this.contacts = hasContactInfos ? _.get(this.staff, 'contact_infos') : this.contacts;
        this.getAllStaffsNotification = !!_.get(this.staff, 'profile.get_noti_all');
        this.introduction = _.get(this.staff, 'profile.self_introduction');
        this.careers = _.get(this.staff, 'careers');
        if (!this.careers.length) this.careers = [{ career: '' }];
        this.privateStartIntervalOn = !!_.get(this.staff, 'profile.private_start_interval_on_off');
        this.privateStartIntervalAm = _.get(this.staff, 'profile.private_start_interval_am');
        this.privateStartIntervalPm = _.get(this.staff, 'profile.private_start_interval_pm');

        this.birthday = _.get(this.staff, 'profile.birthday');
        this.gender = _.get(this.staff, 'profile.gender');
        const profileImageUrl = this.$utils.getImageUrl(_.get(this.staff, 'avatars[0]'), '148x148');
        if (profileImageUrl) {
          this.profileImages = [{ url: profileImageUrl }];
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    }

    this.getStaffColors();

    this.$nextTick(() => {
      this.$refs.name.focus();
    });
  },
  watch: {
    firstWeekdays: function() {
      this.schedules.forEach(el => {
        this.firstWeekdays.forEach(item => {
          if (item && this.moment(el.date).weekday() === this.moment(item.date).weekday()) {
            el.isFirstWeekday = el.date === item.date;
          }
        });
      });
    },
  },

  methods: {
    ...mapActions('auth', ['getCurrentUser']),
    updateCareer() {
      this.careers.forEach(item => {
        if (!item.career) item.career = '';
      });
    },
    updateSameWeekdayTimes(time_data) {
      this.schedules.forEach(el => {
        if (this.moment(time_data.date).weekday() === this.moment(el.date).weekday()) {
          el.open = _.cloneDeep(time_data.open);
          el.closed = _.cloneDeep(time_data.closed);
          el.isHoliday = _.cloneDeep(time_data.isHoliday);
        }
      });
    },
    //강사 근무 시간 data 분리
    seperateWorkTime(dataSet) {
      const exists = _.filter(dataSet, el => {
        return el.date;
      });
      if (exists) {
        exists.forEach(({ date }) => {
          this.existingBookingDates.push(date);
        });
      }

      this.workingHoursByWeekday = this.groupWorkingHoursByWeekday(
        _.filter(dataSet, el => {
          return !el.date;
        }),
      );
    },
    /** 강사 근무 시간 get*/
    async getStaffWorkTimes(id) {
      const res = await this.$api.staff.workTimeSchedule(id);
      this.seperateWorkTime(res.data); //이벤트성 데이터중 날짜만 분리
      this.eventWorktimeDatas = _.filter(res.data, ({ date }) => {
        //이벤트성 데이터 모음
        return date;
      });
    },
    /** 다른 강사의 색상 정보 */
    async getStaffColors() {
      let staffColors = {};
      try {
        const res = await this.$api.staff.getAll();
        res.data.staffs.forEach(staff => {
          if (!this.staff || this.staff.id != staff.id) {
            const color = _.get(staff, 'profile.representative_color');
            if (!staffColors[color]) staffColors[color] = 1;
            else staffColors[color] += 1;
          }
        });
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }

      this.staffColors = staffColors;
    },

    /** 강사 색상 선택시 */
    handleColorSelect(color) {
      this.selectedColor = color;
      this.showColorsAll = false;
    },

    /** 프로필 이미지 미리보기 */
    handleProfileImagePreview(file) {
      this.profileImagePreviewUrl = file.url;
      this.showProfileImagePreviewDialog = true;
    },

    /** 프로필 이미지 변경시 */
    handleProfileImageChange(file, fileList) {
      if (file.size > 2 * 1024 * 1024) {
        this.$utils.notify.error(this, '오류', '이미지 사이즈는 2MB까지 가능합니다.');
        return fileList.splice(fileList.length - 1, 1);
      }
      if (fileList.length > 1) fileList.shift();
      if (fileList.length > 0) {
        this.profileImage = fileList[fileList.length - 1];
      }
    },

    /** 프로필 이미지 제거 */
    handleClickRemoveProfileImage() {
      this.$refs['profile-image'].clearFiles();
      this.profileImage = null;
      this.profileImages = [];
    },

    /** 연락처 추가 */
    handleClickAddContact() {
      this.contacts.push({ type_id: 1, contact: '', is_representative: false });
    },

    /** 연락처 제거 */
    handleClickRemoveContact(index) {
      this.contacts.splice(index, 1);
    },

    /** 이력 추가 */
    handleClickAddCareer() {
      this.careers.push({ career: '' });
    },

    /** 이력 제거 */
    handleClickRemoveCareer(index) {
      this.careers.splice(index, 1);
    },

    /** 근무 시간 초기값 설정 */
    setWorkingHours() {
      let mapped = {};

      _.range(7).forEach(weekday => {
        mapped[weekday] = {
          open: [{ start: '09:00', end: '18:00' }],
          closed: [],
          isHoliday: false,
        };
      });

      this.workingHoursByWeekday = mapped;
    },

    /** 휴식시간 추가 */
    addRestHour(weekday) {
      const { open } = this.workingHoursByWeekday[weekday];
      this.workingHoursByWeekday[weekday].closed.push({
        start: open[0].start,
        end: this.$utils.convertTime.addThirtyMinutes(open[0].start),
      });
    },

    /** 휴식시간 제거 */
    removeRestHour(weekday, closedHourIndex) {
      this.workingHoursByWeekday[weekday].closed.splice(closedHourIndex, 1);
    },

    /** 근무시간 일괄 적용 */
    applyToAllOpenHours() {
      const { start, end } = this.workingHoursByWeekday[0].open[0];
      const { closed } = this.workingHoursByWeekday[0];
      Object.keys(this.workingHoursByWeekday).forEach(weekday => {
        if (!this.workingHoursByWeekday[weekday].isHoliday) {
          this.workingHoursByWeekday[weekday].open = [{ start, end }];
          this.workingHoursByWeekday[weekday].closed = [...closed];
        }
      });
    },
    /** 근무시간 기존 데이터 삭제 */
    async removeExistData(data) {
      const title = '휴일 삭제';
      const message = '설정한 휴일을 삭제하시겠습니까?';
      const proceed = await this.$confirm(message, title)
        .then(() => true)
        .catch(() => false);
      if (proceed) {
        this.existingBookingDates = this.existingBookingDates.filter(el => el !== data.date);
        this.eventWorktimeDatas = this.eventWorktimeDatas.filter(el => el.date !== data.date);
        this.schedules = this.schedules.filter(schedule => schedule.date !== data.date);
      }
    },
    addRestRow(row_data) {
      const value_fn = el => row_data.date === el.date;
      const value_number = this.schedules.findIndex(value_fn);

      if (row_data.add_number === '00') {
        this.schedules[value_number].closed.push({
          start: this.schedules[value_number].open[0].start,
          end: this.$utils.convertTime.addThirtyMinutes(this.schedules[value_number].open[0].start),
        });
      } else {
        this.schedules[value_number].closed.push(_.cloneDeep(this.schedules[value_number].closed[row_data.add_number]));
      }
    },
    removeRestRow(row_data) {
      const value_fn = el => row_data.date === el.date;
      const value_number = this.schedules.findIndex(value_fn);
      this.schedules[value_number].closed.splice([row_data.remove_no], 1);
    },

    async saveStaff() {
      if (!this.validate()) return;

      try {
        this.isSaving = true;

        await this.updateCareer();
        const json = {
          role_id: this.roleId,
          profile: {
            name: this.name,
            representative_color: this.selectedColor,
            registered_at: this.registeredAt,
            self_introduction: this.introduction,
            get_noti_all: this.getAllStaffsNotification ? 1 : 0,
            private_start_interval_on_off: this.privateStartIntervalOn ? 1 : 0,
            private_start_interval_am: this.privateStartIntervalAm,
            private_start_interval_pm: this.privateStartIntervalPm,
            gender: this.gender,
            birthday: this.birthday,
          },
          contactInfos: this.contacts
            .filter(({ contact }) => contact)
            .map(({ type_id, contact, is_representative }) => {
              return {
                type_id,
                contact,
                is_representative: is_representative ? 1 : 0,
              };
            }),

          careers: this.isBlankCareer ? -1 : this.careers.filter(({ career }) => career),
        };
        const formData = this.$utils.generateFormData(json);
        if (this.profileImage && this.profileImage.raw) {
          let convertedFile;
          if (this.profileImage.raw.type === 'image/png') {
            await this.$utils.convertPNGToJPEG(this.profileImage.raw).then(jpgFile => {
              convertedFile = jpgFile;
            });
          }
          formData.append('representativeAvatar[image]', convertedFile || this.profileImage.raw);
        } else if (!this.profileImage && !this.profileImages.length && !!this.staff && !!this.staff.id) {
          await this.$api.staff.deleteAvatar(this.staff.id);
        }

        const staffId = _.get(this.staff, 'id');

        const res = staffId ? await this.$api.staff.update(staffId, formData) : await this.$api.staff.create(formData);

        if (!staffId) {
          const title = '강사 등록 완료';
          const message = `강사가 등록되었습니다.`;

          await this.$alert(message, title, {
            dangerouslyUseHTMLString: true,
          })
            .then(() => true)
            .catch(() => false);
        } else {
          this.$utils.notify.success(this, '성공', '저장되었습니다.');
        }

        await this.$api.staff.workTimeUpdate(res.data, [...this.updateWorkTimeDataFormat(), ...this.updateEventWorkTime()]); // new api 강사 근무시간 저장
        this.schedules = [];
        this.$router.push(`/staffs/detail?id=${res.data}`);
        // 수정 후 navBar 업데이트를 위한 dispatch
        this.getCurrentUser(false);
      } catch (error) {
        const errors = error?.response?.data?.errors;

        if (errors) {
          // 강사 아바타 이미지 에러
          const representativeAvatarError = errors['representativeAvatar.image']?.[0];
          // 휴대전화 1개이상 등록시 에러
          const contactInfoError = errors['contactInfos']?.[0];

          if (representativeAvatarError) {
            this.$utils.notify.parseErrorImage(this, error, 'image');
          } else if (contactInfoError) {
            this.$utils.notify.parseError(this, { message: contactInfoError });
          } else {
            this.$utils.notify.parseError(this, error);
          }
        } else {
          this.$utils.notify.parseError(this, error);
        }
      } finally {
        this.isSaving = false;
      }
    },

    validate() {
      const { notify, validate } = this.$utils;
      if (!this.name) {
        notify.error(this, '오류', '이름을 입력해주세요.');
        this.$refs.name.focus();
        return false;
      } else if (this.name.length > 20) {
        notify.error(this, '오류', '이름은 20글자까지만 입력해주세요.');
        return false;
      } else if (!validate.name(this.name)) {
        notify.error(this, '오류', '이름은 한글, 영문, 숫자만 입력해주세요.');
        this.$refs.name.focus();
        return false;
      } else if (!this.roleId) {
        notify.error(this, '오류', '역할을 선택해주세요.');
        this.$refs.roleId.$el.querySelector('input').focus();
        return false;
      } else if (!!this.contacts[0].contact && !this.$utils.validate.mobile(this.contacts[0].contact)) {
        this.$utils.notify.error(this, '오류', '휴대폰 번호를 올바르게 입력해주세요.');
        return false;
      } else if (
        this.checkBreakExceedsWorkInWeek(this.workingHoursByWeekday) ||
        this.checkBreakExceedsWorkInEventSchedules(this.schedules)
      ) {
        notify.error(this, '오류', '휴식시간은 근무시간을 초과할 수 없습니다.');
        return false;
      }

      return true;
    },

    groupWorkingHoursByWeekday(el = {}) {
      let mappedResult = {};
      _.range(7).forEach(weekday => {
        //새로운 요일별 array 생성
        mappedResult[weekday] = {
          open: [{ start: '09:00', end: '18:00' }],
          closed: [],
          isHoliday: true,
        };
      });

      el.forEach(item => {
        const start = _.get(item, 'start_time').slice(0, 5); //시간 중 초는 제외하고 분까지만 가져옴
        const end = _.get(item, 'end_time').slice(0, 5);
        const day_of_week = _.get(item, 'day_of_week'); //요일 get
        const weekday = day_of_week === 0 ? 6 : day_of_week - 1;
        mappedResult[weekday] = {
          ...mappedResult[weekday],
          isHoliday: false,
        };
        if (item.type === 'workTime' || item.type === 'eventWorkTime') {
          _.remove(mappedResult[weekday].open, mappedResult[weekday].open[0]);
          mappedResult[weekday].open.push({ start, end });
        } else {
          mappedResult[weekday].closed.push({ start, end });
          mappedResult[weekday].closed = _.sortBy(mappedResult[weekday].closed, ['start']);
        }
      });

      return mappedResult;
    },

    /** 기본 근무/휴식시간 서버 전송용으로 변환 */
    updateWorkTimeDataFormat() {
      let result = [];
      let data = this.workingHoursByWeekday;
      for (let el in data) {
        if (!data[el].isHoliday) {
          for (let timeType in data[el]) {
            if (timeType !== 'isHoliday') {
              data[el][timeType].forEach(time => {
                result.push({
                  type: timeType === 'open' ? 'workTime' : 'restTime',
                  day_of_week: el == 6 ? 0 : Number(el) + 1,
                  start_time: `${time.start}:00`,
                  end_time: `${time.end}:00`,
                });
              });
            }
          }
        }
      }
      return result;
    },

    /** 이벤트성 근무/휴식시간 서버 전송용으로 변환 */
    updateEventWorkTime() {
      const result = [];

      this.schedules.forEach(el => {
        // 달력에서 선택된 데이터
        if (!el.isHoliday) {
          for (let item in el) {
            if (item === 'open' || item === 'closed') {
              el[item].forEach(item_time => {
                result.push({
                  date: el.date,
                  type: item === 'open' ? 'eventWorkTime' : 'eventRestTime',
                  day_of_week: this.moment(el.date).weekday(),
                  start_time: `${item_time.start}:00`,
                  end_time: `${item_time.end}:00`,
                });
              });
            }
          }
        } else {
          // 이벤트성 휴무일일경우
          result.push({
            date: el.date,
            type: 'eventRestTime',
            day_of_week: this.moment(el.date).weekday(),
            start_time: '00:00:00',
            end_time: '23:59:59',
          });
        }
      });

      //달력에서 선택되지 않은 기존 데이터
      const resultExist = this.eventWorktimeDatas
        .filter(item => result.every(currentValue => currentValue.date !== item.date))
        .map(el => {
          return {
            date: el.date,
            type: el.type,
            day_of_week: el.day_of_week,
            start_time: el.start_time,
            end_time: el.end_time,
          };
        });

      return [...result, ...resultExist];
    },

    handlePickDate(date) {
      date = date.format('YYYY-MM-DD');
      const date_set = [];
      this.schedules.forEach(({ date }) => {
        date_set.push(date);
      });
      const dateExists = date_set.includes(date);
      if (dateExists) {
        //기존에 있는 날짜 클릭시 선택 해제
        this.schedules = this.schedules.filter(schedule => schedule.date !== date);
      } else {
        this.existingBookingDates.includes(date) ? this.checkEventWorkTime(date) : this.checkRegularWorkTime(date);
      }
      this.schedules = _.sortBy(this.schedules, ['date']);
    },
    checkEventWorkTime(clickdate) {
      const sort_data = _.sortBy(this.eventWorktimeDatas, 'type').reverse();

      sort_data.forEach(el => {
        if (el.date === clickdate) {
          el.type === 'eventWorkTime'
            ? this.schedules.push({
                date: clickdate,
                open: [{ start: el.start_time.substring(0, 5), end: el.end_time.substring(0, 5) }],
                closed: [],
                isHoliday: false,
                isReserved: true,
              })
            : this.changeFormatEventRestTime(clickdate, el);
        }
      });
    },
    changeFormatEventRestTime(clickdate, { start_time, end_time }) {
      this.schedules.forEach(item => {
        if (clickdate === item.date) {
          item.closed.push({
            start: start_time.substring(0, 5),
            end: end_time.substring(0, 5),
          });
        }
      });
      if (start_time === '00:00:00' && end_time === '23:59:59') {
        this.schedules.push({
          date: clickdate,
          open: [{ start: '09:00', end: '18:00' }],
          closed: [],
          isHoliday: true,
          isReserved: true,
        });
      }
    },
    checkRegularWorkTime(date) {
      //주간 스케줄에서 같은 요일의 데이터를 가져오기
      const updatedData = _.cloneDeep(this.workingHoursByWeekday);

      for (let el in updatedData) {
        el == '6' ? (updatedData[el].day_of_week = 0) : (updatedData[el].day_of_week = Number(el) + 1);
        if (updatedData[el].day_of_week === this.moment(date).weekday()) {
          this.schedules.push({
            date: date,
            closed: updatedData[el].closed,
            isHoliday: updatedData[el].isHoliday,
            open: updatedData[el].open,
          });
        }
      }
    },

    /** 근무시간 중 휴식시간 초과 검증 */
    isBreakExceedingWork(workStart, workEnd, breakStart, breakEnd) {
      const isStartTimeInRange = breakStart.isBetween(workStart, workEnd, undefined, '[]');
      const isEndTimeInRange = breakEnd.isBetween(workStart, workEnd, undefined, '[]');

      return !isStartTimeInRange || !isEndTimeInRange;
    },

    /** 근무시간을 초과하는 휴식시간이 있는지 검증 */
    checkBreakExceedsWorkInWeek(workingHoursByWeekday) {
      for (const weekday in workingHoursByWeekday) {
        const workHours = workingHoursByWeekday[weekday].open[0];
        const breakTimes = workingHoursByWeekday[weekday].closed;
        const workStart = this.moment(workHours.start, 'HH:mm');
        const workEnd = this.moment(workHours.end, 'HH:mm');

        if (!breakTimes || breakTimes.length === 0) continue;

        for (const breakTime of breakTimes) {
          const breakStart = this.moment(breakTime.start, 'HH:mm');
          const breakEnd = this.moment(breakTime.end, 'HH:mm');

          if (this.isBreakExceedingWork(workStart, workEnd, breakStart, breakEnd)) {
            return true;
          }
        }
      }
      return false;
    },

    /** 특정일 근무시간 중 근무시간 초과하는 휴식시간이 있는지 검증 */
    checkBreakExceedsWorkInEventSchedules() {
      return this.schedules.some(schedule => {
        if (schedule.isHoliday) {
          return false;
        }
        const workHours = schedule.open[0];
        const breakTimes = schedule.closed;
        const workStart = this.moment(workHours.start, 'HH:mm');
        const workEnd = this.moment(workHours.end, 'HH:mm');

        return breakTimes.some(breakTime => {
          const breakStart = this.moment(breakTime.start, 'HH:mm');
          const breakEnd = this.moment(breakTime.end, 'HH:mm');

          return this.isBreakExceedingWork(workStart, workEnd, breakStart, breakEnd);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-form__header {
  display: grid;
  align-items: start;
  grid-template-columns: 1fr;
  grid-template-areas: 'image' 'name' 'role' 'registered-at' 'gender-birthday';
  grid-gap: 10px;
  width: 100%;

  @include mq(568px) {
    grid-column-gap: 20px;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'name image'
      'role image'
      'registered-at image'
      'gender-birthday image';
  }

  /* @include mq(768px) {
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'name name image'
      'role registered-at image';
  } */

  &__profile-image {
    grid-area: image;
    justify-self: center;
    position: relative;
    width: 148px;
    height: 148px;
    overflow: hidden;

    &__remove {
      position: absolute;
      background: $gray-100;
      border: 1px solid $gray-200;
      top: 0;
      right: 0;
    }

    .hasProfileImage {
      /deep/ .el-upload {
        display: none;
      }
    }

    /deep/ .el-upload {
      border-radius: 50%;

      input {
        display: none;
      }
    }
  }

  &__name {
    grid-area: name;
    background: transparent;
    font-size: 28px;
    font-weight: bold;
    outline: none;
    width: 100%;

    @include mq(568px) {
      font-size: 38px;
    }
  }

  &__role {
    grid-area: role;
    max-width: 200px;

    &.el-select {
      width: 100%;
    }

    /deep/ .el-input__inner {
      border: 1px solid $gray-200;
      border-radius: 4px;
      padding-left: 10px;
    }
  }

  &__registered-at {
    grid-area: registered-at;

    /deep/ .el-date-editor {
      margin-left: 10px;
      width: 160px;
    }
  }

  &__gender-birthday {
    grid-area: gender-birthday;
  }

  &__gender {
    /deep/ .el-input {
      max-width: 100px;
      margin: 0 24px 0 16px;
    }

    /deep/ .el-input__inner {
      padding-left: 8px;
    }
  }

  &__birthday {
    width: 160px;
    margin-left: 16px;

    /deep/ .el-input__inner {
      padding-left: 8px;
    }

    &.el-date-editor {
      width: 160px;
      margin-left: 16px;
    }
  }
}

.staff-form__body {
  padding: 10px 0px 60px;
  max-width: 1280px;
  margin: auto;

  @include mq(768px) {
    padding: 30px 0px 60px;
  }
}

.staff-form-element {
  &__color {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 8px;
    align-items: start;

    &__chips {
      display: grid;
      grid-template-columns: repeat(11, 32px);
      grid-gap: 8px;
    }

    &__add-new {
      width: 100px;
      height: 32px;
    }

    &__helper {
      grid-column: span 2;
      height: 20px;
      opacity: 0.8;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.6px;
      color: $coral;
    }
  }

  &__contacts {
    &__helper {
      height: 20px;
      opacity: 0.8;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.6px;
      color: $coral;
    }
  }

  &__get-all-notification {
    color: $dodger-blue;
    font-size: 12px;
    font-weight: 500;
  }

  &__introduction,
  &__careers {
    max-width: 600px;

    label {
      font-size: 12px;
      font-weight: 500;
    }
  }

  &__careers {
    margin-top: 12px;

    ul {
      @include flex(column);

      li {
        display: grid;
        grid-template-columns: 1fr 32px;
        grid-gap: 8px;
        margin-bottom: 8px;
      }
    }
  }

  &__work-months {
    .el-date-editor {
      max-width: 260px;
    }
    .el-button--mini {
      padding: 5px 10px;
      margin-left: 10px;
    }
  }

  &__work-calendar {
    display: grid;
    grid-template-columns: 320px 1fr;
    .txt-selected-dates {
      margin-top: 21px;
      button {
        margin-left: 10px;
      }
    }
  }
}

.txt-info-weekday {
  margin-bottom: 30px;
}
.wrap-calendar-info-txt {
  @include flex(row, center, flex-start);
  margin: 0px 0 10px 0;
}
.reserved-circle {
  display: inline-block;
  margin: 0 5px 0 0px;
  width: 6px;
  height: 6px;
  background: $dodger-blue;
  border-radius: 50%;
}
.calendar-specific-txt {
  font-size: 12px;
  color: $gray-600;
  padding-left: 10px;
}
.txt-exist-specific {
  font-size: 12px;
  ul {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.block {
  margin-top: 15px;
  border-left: 4px solid $gray-200;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 14px;
  padding-left: 15px;
  color: $charcoal-grey;
  gap: 15px;

  .time {
    margin-left: 10px;
    margin-right: 30px;
  }
  .line {
    display: flex;
    align-items: center;
  }
}
</style>
